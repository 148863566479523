import React from "react";
import VenueType from "./VenueType";
import VenueTags from "./VenueTags";
import SsidInput from "./SsidInput";
import "./VenueEditor.css";

const defaultBssTokenType = "s";
const defaultMatchType = "OVERRIDE";
const defaultAffinity = "100";

class VenueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onNameChange = this.onNameChange.bind(this);
    this.saveDisabled = this.saveDisabled.bind(this);
    this.onNameBlur = this.onNameBlur.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.onSave = this.onSave.bind(this);
    const venueTokenObject = this.props.venueTokenObject;
    this.state = {
      venueToken: venueTokenObject.venueToken,
      venueName: venueTokenObject.venueName,
      venueType: venueTokenObject.venueType,
      tags: venueTokenObject.tags,
      bssTokens: venueTokenObject.bssTokens.map((e, index) =>
        Object.assign({}, e, { key: index })
      ),
      newTokenType: defaultBssTokenType,
      newToken: "",
      newMatchType: defaultMatchType,
      newAffinity: defaultAffinity,
      venueTokenUnique: this.props.create ? undefined : true
    };
  }

  saveDisabled() {
    if (this.state.venueToken === "" || this.state.venueToken === undefined) {
      return true;
    }

    if (this.state.venueTokenUnique === undefined) {
      return true;
    }

    if (this.state.venueTokenUnique === false) {
      return true;
    }

    var foundBadBssToken = false;

    this.state.bssTokens.forEach((bssToken, i) => {
      var [bssTokenType, token] = bssToken.bssToken.split(":");

      var bssTokenProblem = this.checkBssToken(token, bssTokenType);
      if (bssTokenProblem != null) {
        foundBadBssToken = true;
      }
    });

    if (foundBadBssToken) {
      return true;
    }

    return false;
  }

  asciiToHex(ascii) {
    var hex = [];
    for (var n = 0, l = ascii.length; n < l; n++) {
      hex.push(Number(ascii.charCodeAt(n)).toString(16));
    }
    return hex.join("");
  }

  onNameChange(event) {
    this.setState({ venueName: event.target.value });
    if (this.props.create) {
      this.setState({
        venueToken: this.asciiToHex(event.target.value),
        venueTokenUnique: undefined
      });
    }
  }

  onNameBlur(event) {
    var self = this;
    if (this.props.create) {
      this.props.venueTokenExists(this.state.venueToken, function(err, res) {
        if (res) {
          self.setState({
            venueTokenUnique: false
          });
        } else {
          self.setState({
            venueTokenUnique: true
          });
        }
      });
    }
  }

  onTypeChange(venueType) {
    this.setState({ venueType });
  }

  onTagsChange(tags) {
    this.setState({ tags });
  }

  onDeleteBssToken(i) {
    this.setState({
      bssTokens: [
        ...this.state.bssTokens.slice(0, i),
        ...this.state.bssTokens.slice(i + 1)
      ]
    });
  }

  changeBssToken(i, newBssToken) {
    this.setState({
      bssTokens: [
        ...this.state.bssTokens.slice(0, i),
        newBssToken,
        ...this.state.bssTokens.slice(i + 1)
      ]
    });
  }

  onChangeToken(token, i) {
    var currentBssToken = this.state.bssTokens[i];
    var bssTokenType = currentBssToken.bssToken.split(":")[0];
    this.changeBssToken(
      i,
      Object.assign(currentBssToken, {
        bssToken: bssTokenType + ":" + token
      })
    );
  }

  onChangeTokenType(tokenType, i) {
    var currentBssToken = this.state.bssTokens[i];
    var token = currentBssToken.bssToken.split(":")[1];
    this.changeBssToken(
      i,
      Object.assign(currentBssToken, {
        bssToken: tokenType + ":" + token
      })
    );
  }

  onChangeAffinity(affinity, i) {
    this.changeBssToken(
      i,
      Object.assign(this.state.bssTokens[i], {
        affinity: parseInt(affinity)
      })
    );
  }

  onChangeMatchType(matchType, i) {
    this.changeBssToken(
      i,
      Object.assign(this.state.bssTokens[i], { matchType })
    );
  }

  onSave() {
    // Write all venueToken properties regardless if anything changed.
    var newVenueTokenItem = {
      venueToken: this.state.venueToken,
      venueName: this.state.venueName,
      venueType: this.state.venueType,
      tags: this.state.tags
    };

    var bssTokensToDelete = [];
    var bssTokensToWrite = [];
    var unchangedBssTokens = [];

    // All bssTokens in the props that are no longer in the state are to be deleted.
    this.props.venueTokenObject.bssTokens.forEach(bssToken => {
      var i = this.state.bssTokens.findIndex(
        t => t.bssToken === bssToken.bssToken
      );
      if (i === -1) {
        bssTokensToDelete.push(bssToken.bssToken);
      }
    });

    // Collect the bssTokens to be written.  For existing bssTokens we overwrite the entire item.
    // - All bssTokens in the state that have no corresponding token in the
    // props are new.
    // - All bssTokens in the state that are different from the corresponding token
    // in the props need to be overwritten.
    this.state.bssTokens.forEach(stateBssToken => {
      var _stateBssToken = {
        bssToken: stateBssToken.bssToken,
        venueToken: this.state.venueToken,
        matchType: stateBssToken.matchType,
        affinity: stateBssToken.affinity
      };
      var i = this.props.venueTokenObject.bssTokens.findIndex(
        t => t.bssToken === stateBssToken.bssToken
      );
      if (i === -1) {
        // This is a new bssToken.
        bssTokensToWrite.push(_stateBssToken);
      } else {
        var propsBssToken = this.props.venueTokenObject.bssTokens[i];

        if (
          propsBssToken.matchType !== stateBssToken.matchType ||
          propsBssToken.affinity !== stateBssToken.affinity
        ) {
          bssTokensToWrite.push(_stateBssToken);
        } else {
          unchangedBssTokens.push(_stateBssToken);
        }
      }
    });
    this.props.onSave({
      newVenueTokenItem,
      bssTokensToDelete,
      bssTokensToWrite,
      unchangedBssTokens
    });
  }

  checkBssToken(token, bssTokenType) {
    if (bssTokenType === "s") {
      var hexre = /^[0-9A-Fa-f]+$/;
      if (token.length % 2 !== 0) {
        return "Invalid length";
      }
      if (!hexre.test(token)) {
        return "Invalid hex";
      }
    } else if (bssTokenType === "n") {
      var digitre = /^[0-9]+$/;
      if (!digitre.test(token)) {
        return "Invalid networkId";
      }
    }
    return null;
  }

  newBssTokenIsValid(newTokenType, newToken, newMatchType, newAffinity) {
    if (
      newTokenType === "CHOOSE" ||
      newMatchType === "CHOOSE" ||
      newToken === "" ||
      newAffinity === ""
    ) {
      return false;
    } else {
      var problem;
      problem = this.checkBssToken(newToken, newTokenType);
      if (problem) {
        return problem;
      }
      return true;
    }
  }

  render() {
    const bssTokens = [];

    var newTokenIsValid = this.newBssTokenIsValid(
      this.state.newTokenType,
      this.state.newToken,
      this.state.newMatchType,
      this.state.newAffinity
    );

    var newTokenInput;

    if (this.state.newTokenType === "s") {
      newTokenInput = (
        <SsidInput
          size="64"
          onChange={ssid => this.setState({ newToken: ssid })}
        />
      );
    } else if (this.state.newTokenType === "n") {
      newTokenInput = (
        <input
          className="networkIdInput"
          type="number"
          defaultValue={this.state.newToken}
          placeholder="NetworkId..."
          size="64"
          min="0"
          onChange={e => this.setState({ newToken: e.target.value })}
        />
      );
    } else {
      newTokenInput = (
        <input
          type="text"
          defaultValue={this.state.newToken}
          placeholder="New token..."
          size="64"
          onChange={e => this.setState({ newToken: e.target.value })}
          autoComplete="off"
        />
      );
    }

    // The first row contains the inputs associated with addind a new bssToken
    bssTokens.push(
      <tr id="bssToken" key={this.state.bssTokens.length}>
        <td>{newTokenInput}</td>
        <td>
          <select
            value={this.state.newTokenType}
            onChange={e => this.setState({ newTokenType: e.target.value })}
          >
            <option value="CHOOSE">Choose...</option>
            <option value="s">SSID</option>
            <option value="w">WISPR Location Name</option>
            <option value="l">Location Token</option>
            <option value="n">Network Id</option>
          </select>
        </td>
        <td>
          <select
            value={this.state.newMatchType}
            onChange={e => this.setState({ newMatchType: e.target.value })}
          >
            <option value="CHOOSE">Choose...</option>
            <option value="OVERRIDE">OVERRIDE</option>
            <option value="DEDICATED">DEDICATED</option>
            <option value="SHARED">SHARED</option>
            <option value="DEFAULT">DEFAULT</option>
          </select>
        </td>
        <td>
          <input
            className="affinityInput"
            type="number"
            defaultValue={this.state.newAffinity}
            min="0"
            max="1000"
            onChange={e => this.setState({ newAffinity: e.target.value })}
          />
        </td>
        <td>
          <input
            type="button"
            onClick={() => {
              this.setState({
                bssTokens: [
                  {
                    bssToken:
                      this.state.newTokenType + ":" + this.state.newToken,
                    matchType: this.state.newMatchType,
                    affinity: this.state.newAffinity,
                    key: this.state.bssTokens.length
                  },
                  ...this.state.bssTokens
                ],
                newToken: ""
              });
            }}
            value="Add"
            disabled={!newTokenIsValid}
          />
        </td>
      </tr>
    );

    this.state.bssTokens.forEach((bssToken, i) => {
      var [bssTokenType, token] = bssToken.bssToken.split(":");
      var affinity = bssToken.affinity;
      var matchType = bssToken.matchType;

      var bssTokenProblem = this.checkBssToken(token, bssTokenType);

      var tokenInput;
      if (bssTokenType === "s") {
        tokenInput = (
          <SsidInput
            ssid={token}
            size="64"
            onChange={ssid => this.onChangeToken(ssid, i)}
          />
        );
      } else if (bssTokenType === "n") {
        tokenInput = (
          <input
            className="networkIdInput"
            type="number"
            defaultValue={token}
            min="1"
            onChange={event => {
              this.onChangeToken(event.target.value, i);
            }}
          />
        );
      } else {
        tokenInput = (
          <input
            type="text"
            defaultValue={token}
            size="64"
            onChange={event => {
              this.onChangeToken(event.target.value, i);
            }}
            autoComplete="off"
          />
        );
      }

      bssTokens.push(
        <tr id="bssToken" key={bssToken.key}>
          <td>{tokenInput}</td>
          <td>
            <select
              value={bssTokenType}
              onChange={event => {
                this.onChangeTokenType(event.target.value, i);
              }}
            >
              <option value="s">SSID</option>
              <option value="w">WISPR Location Name</option>
              <option value="l">Location Token</option>
              <option value="n">Network Id</option>
            </select>
          </td>
          <td>
            <select
              value={matchType}
              onChange={event => {
                this.onChangeMatchType(event.target.value, i);
              }}
            >
              <option value="OVERRIDE">OVERRIDE</option>
              <option value="DEDICATED">DEDICATED</option>
              <option value="SHARED">SHARED</option>
              <option value="DEFAULT">DEFAULT</option>
            </select>
          </td>
          <td>
            <input
              className="affinityInput"
              type="number"
              defaultValue={affinity}
              min="0"
              max="1000"
              onChange={event => {
                this.onChangeAffinity(event.target.value, i);
              }}
            />
          </td>
          <td>
            <input
              type="button"
              onClick={() => {
                this.onDeleteBssToken(i);
              }}
              value="Delete"
            />
          </td>
          <td>{bssTokenProblem}</td>
        </tr>
      );
    });

    var venueTokenElement;
    if (this.props.create) {
      switch (this.state.venueTokenUnique) {
        case true:
          venueTokenElement = (
            <div className="uniqueVenueToken">{this.state.venueToken}</div>
          );
          break;
        case false:
          venueTokenElement = (
            <div className="duplicateVenueToken">{this.state.venueToken}</div>
          );
          break;
        case undefined:
        default:
          venueTokenElement = <div>{this.state.venueToken}</div>;
          break;
      }
    } else {
      venueTokenElement = <div>{this.state.venueToken}</div>;
    }

    return (
      <div id="editor">
        <table>
          <tbody>
            <tr>
              <td>Venue Token</td>
              <td>{venueTokenElement}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="venueName">Venue Name</label>
              </td>
              <td>
                <input
                  id="venueName"
                  type="text"
                  size="64"
                  defaultValue={this.state.venueName}
                  onChange={this.onNameChange}
                  onBlur={this.onNameBlur}
                  autoComplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>Venue Type</td>
              <td>
                <VenueType
                  id="venueType"
                  type={this.state.venueType}
                  onChange={this.onTypeChange}
                />
              </td>
            </tr>
            <tr>
              <td>Tags</td>
              <td>
                <VenueTags
                  tags={this.state.tags}
                  onChange={this.onTagsChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div className="editorElement">
          <table>
            <caption>BssTokens</caption>
            <thead>
              <tr>
                <th>Token</th>
                <th>Token Type</th>
                <th>Match Type</th>
                <th>Affinity</th>
              </tr>
            </thead>
            <tbody>{bssTokens}</tbody>
          </table>
        </div>
        <input
          type="button"
          value="Save"
          onClick={this.onSave}
          disabled={this.saveDisabled()}
        />
        <input type="button" value="Cancel" onClick={this.props.onCancel} />
      </div>
    );
  }
}

export default VenueEditor;
